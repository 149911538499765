import { combineReducers } from 'redux';
import rootReducer from './Redux/LoginReducer';
import profileReducer from './Redux/ProfileReducer';
import payment_reducer from './Redux/PricingReducer';
import URL_reducer from './Redux/URLReducer';
import resume_reducer from './Redux/ResumeReducer';
import height_reducer from './Redux/HeightReducer';
import scrollable_reducer from './Redux/ScrollableReducer';
import NewsReducer from './Redux/NewsReducer';
import News_Reducer from './Redux/NewsReducer';
import get_scholarship from './Redux/GetScholarshipReducer';

const combinedReducers = combineReducers({
    rootReducer,
    profileReducer,
    payment_reducer,
    URL_reducer,
    resume_reducer,
    height_reducer,
    scrollable_reducer,
    News_Reducer,
    get_scholarship
});

export default combinedReducers;
