import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ViewProfile from "../Modals/ViewProfile";
import SpinnerLoader from "../../webLoader/SpinnerLoader";
import {
  roundAndFormatNumberString,
  styleComponent,
} from "../../constant/commonUtils";
import { data_error } from "../../constant/WebText";
import { height_action } from "../../Redux/HeightAction";
import { scroll_action } from "../../Redux/ScrollableAction";
import { users_data } from "../../Redux/ProfileAction";
import MaskUser from "../Dashoard/MaskUser";
import ViewAll from "../Dashoard/ViewAll";
import Calendar from "../Dashoard/Calander";
import DashboardTab from "./DashboardTab";

export default function Users(props) {
  const { spinnerLoader } = props;
  const usersSelector = useSelector((state) => state.profileReducer);
  const [data, setData] = useState([]);
  const [modalShow2, setModalShow2] = useState(false);
  const [selecteduser, setSelectedUser] = useState("");
  const dispatch = useDispatch();
  const tokenSelector = useSelector((state) => state.rootReducer?.user?.token);
  const chooseUser = useSelector((state) => state.rootReducer);

  const handleDetailClick = (userId) => {
    setSelectedUser(userId.id);
    setModalShow2(true);
  };

  useEffect(() => {
    if (usersSelector?.usersData.length > 0) {
      setData(
        usersSelector?.usersData.map((user) => ({
          id: user.id,
          name: user.name,
          email: user.contact,
          // score: roundAndFormatNumberString(user.score),
          payment_status: user.payment_status,
        }))
      );
    }
  }, [usersSelector]);

  useEffect(() => {
    dispatch(height_action(data, "list"));
    // eslint-disable-next-line
  }, [data]);

  const reduxHeight = useSelector((state) => state.height_reducer);
  const dataLength = reduxHeight?.data?.data;

  const containerRef = useRef(null);
  const container = containerRef.current;

  useEffect(() => {
    if (dataLength && container) {
      setTimeout(() => {
        if (container && container.scrollHeight > container.clientHeight) {
          dispatch(scroll_action(true));
        } else {
          dispatch(scroll_action(false));
        }
      }, 100);
    }
    // eslint-disable-next-line
  }, [dataLength, container, data]);

  return (
    <>
      {/* {spinnerLoader && <SpinnerLoader />} */}

      {/* <div className="user-list-tble-holder table-responsive">
          <Table className="users-list-table">
            {!spinnerLoader &&  data.length > 0 ?  <>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Payment Status</th>
                <th></th>
              </tr>
            </thead>
            <div className="user-responsive" ref={containerRef}>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data?.map((user, index) => (
                    <tr key={index}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.payment_status}</td>
                      <td>
                        <Button onClick={() => handleDetailClick(user.id)}>
                          Detail
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
              {data &&
                  data.length === 0 && (
                    <div className="text-center empty-text-holder">
                      <h3>
                        {data_error}
                      </h3>
                    </div>
                  )}
            </div>
             </>: <div className="no_data_found_users"></div> }
          </Table>
        </div> */}

      <div className={"line-chart-main-container-user"}>
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-9 pe-4 progress-3">
              <DashboardTab
                listing={data}
                userAction={handleDetailClick}
                type={"profile"}
                styleComponent={styleComponent}
              />
            </div>

            <div className="col-md-3 col-sm-3 mb-4 ps-0 calander-deshboards">
              <div className="mb-4">
                <Calendar />
              </div>
              <ViewAll />

              {/* <MaskUser /> */}
            </div>
          </div>
        </div>
      </div>

      {modalShow2 && (
        <ViewProfile
          show={modalShow2}
          onHide={() => {
            setModalShow2(false);
            dispatch(users_data("", tokenSelector));
          }}
          selecteduser={selecteduser}
        />
      )}
    </>
  );
}
