import React, { useState } from "react";
import "./Stories.css";
import { Scrollbars } from "react-custom-scrollbars";
import Calendar from "../Dashoard/Calander";
import MaskUser from "../Dashoard/MaskUser";
import ViewAll from "../Dashoard/ViewAll";
import MiniCard from "../Dashoard/MiniCard";
import { cardlisting } from "../../constant/commonUtils";

const Stories = () => {
  const [faqData, setFaqData] = useState(initialFaqData);
  const [activeIndex, setActiveIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedAnswer, setEditedAnswer] = useState("");

  const toggleCollapse = (index) => {
    const currentElement = document.getElementById(`faqCollapse-${index + 1}`);
    const activeElement =
      activeIndex !== null
        ? document.getElementById(`faqCollapse-${activeIndex + 1}`)
        : null;

    if (activeIndex === index) {
      // Slide Up
      currentElement.style.height = `${currentElement.scrollHeight}px`; // Set to full height first
      setTimeout(() => {
        currentElement.classList.add("slide-up");
        currentElement.style.height = "0"; // Collapse
      }, 10);
      setTimeout(() => {
        currentElement.classList.remove("show", "slide-up");
        currentElement.style.height = "auto";
        setActiveIndex(null);
      }, 500);
    } else {
      if (activeElement) {
        // Slide Up previous active element
        activeElement.style.height = `${activeElement.scrollHeight}px`; // Set to full height first
        setTimeout(() => {
          activeElement.classList.add("slide-up");
          activeElement.style.height = "0"; // Collapse previous
        }, 10);
        setTimeout(() => {
          activeElement.classList.remove("show", "slide-up");
          activeElement.style.height = "auto";
        }, 500);
      }

      // Slide Down new element
      currentElement.style.height = "0"; // Set initial height to 0
      currentElement.classList.add("show", "slide-down");
      setTimeout(() => {
        currentElement.style.height = `${currentElement.scrollHeight}px`; // Set to full height
      }, 10);
      setTimeout(() => {
        currentElement.style.height = "auto"; // Allow it to expand naturally
      }, 500);
      setActiveIndex(index);
    }
  };
  const handleEdit = (index) => {
    setEditIndex(index);
    setEditedAnswer(faqData[index].answer);
  };

  const handleSave = (index) => {
    const updatedFaqData = faqData.map((item, i) =>
      i === index ? { ...item, answer: editedAnswer } : item
    );
    setFaqData(updatedFaqData);
    setEditIndex(null);
  };

  const handleChange = (e) => {
    setEditedAnswer(e.target.value);
  };

  return (
    <div className="container faq-section">
      <div className="row faq-tab">
        <div className="col-md-9 col-sm-9 story-according">
          <section className="faq-page">
            <div className="faq" id="accordion">
              <div className="faq-title text-center">
                <h2>Story</h2>
              </div>
              {faqData.map((faq, index) => (
                <div className="card" key={index}>
                  <div className="card-header" id={`faqHeading-${index + 1}`}>
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        onClick={() => toggleCollapse(index)}
                        data-toggle="collapse"
                        data-target={`#faqCollapse-${index + 1}`}
                        aria-expanded={activeIndex === index}
                        aria-controls={`faqCollapse-${index + 1}`}
                      >
                        <span className="badge">{index + 1}</span>{" "}
                        {faq.question}
                      </h5>
                    </div>
                  </div>
                  <div
                    id={`faqCollapse-${index + 1}`}
                    className={`collapse ${
                      activeIndex === index ? "show slide-down" : ""
                    }`}
                    aria-labelledby={`faqHeading-${index + 1}`}
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {editIndex === index ? (
                        <div>
                          <textarea
                            value={editedAnswer}
                            onChange={handleChange}
                            rows="4"
                            className="form-control"
                          />
                          <button
                            onClick={() => handleSave(index)}
                            className="btn btn-primary mt-2"
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p>{faq.answer}</p>
                          <button
                            onClick={() => handleEdit(index)}
                            className="btn btn-secondary mt-2"
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div className="col-md-3 col-sm-3 mb-4 story-content">
          <div>
            <MiniCard cardlisting={[cardlisting[2]]} />
          </div>
          <div className="mb-4">
            <Calendar />
          </div>
          <div className="mb-4">
            <ViewAll />
          </div>

          <MaskUser />
        </div>
      </div>
    </div>
  );
};

const initialFaqData = [
  {
    question: "What is the greatest challenge you have ever faced?",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    question: "How did you overcome it?",
    answer:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  },
  {
    question: "What is the greatest impact you have made on someone’s life?",
    answer:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  },
  {
    question: "What is the most adventurous thing you have ever done?",
    answer:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  },
  {
    question: "Share a time where your leadership greatly impacted others?",
    answer:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  },
  {
    question: "Paint a picture of your life 7-10 years from now",
    answer:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
  },
  // ... other FAQs
];

export default Stories;
