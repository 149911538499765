import React, { useState, useEffect } from "react";
import Footer from "../footer/Footer";
import { pathFinderLogo } from "../../constant/images";
import { useLocation, useNavigate } from "react-router-dom";
import commonApi from "../../CommonApi/CommonServices";
import { FirstStepList, ScndStepList } from "../../constant/commonUtils";
import { errorMessage } from "../../Errors/Toast";
import { useDispatch, useSelector } from "react-redux";
import { onBoarding } from "../../Redux/LoginAction";
import QuetionsModal from "../Modals/QuetionsModal";
import SportsmanshipModal from "../Modals/SportsmanshipModal";
import { url_action } from "../../Redux/URLAction";
// import { AutoComplete } from "rsuite";
import {
  FormControl,
  InputGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

const Onboarding = () => {
  const [onbordingStep, setOnbordingStep] = useState(1);
  const [onboardingQuestion, setOnboardingQuestion] = useState([]);
  const [stepOneSelectedId, setStepOneSelectedId] = useState("");
  const [stepTwoSelectedId, setStepTwoSelectedId] = useState("");
  const [step3to6SelectedIds, setStep3to6SelectedIds] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [questionDetail, setQuestionDetail] = useState({});
  const [selectedQuestionArray, setSelectedQuestionArray] = useState([]);
  const [modalShow1, setModalShow1] = useState(false);
  const [step1SelectedIds, setStep1SelectedIds] = useState([]);
  const [step2SelectedIds, setStep2SelectedIds] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  // List of suggestions from API
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const user_grade = useSelector((state) => state.payment_reducer);
  const grade = user_grade?.type?.grade;

  const navigate = useNavigate();

  const handleStep3to6SelectedIds = (id) => {
    // Check if the ID already exists in the array
    if (!step3to6SelectedIds.includes(id)) {
      // If not, push the ID into the array
      setStep3to6SelectedIds((prevIds) => [...prevIds, id]);
    }
  };
  const handleStep1SelectedIds = (item) => {
    // Set the state to an array containing only the new ID
    setStep1SelectedIds([item.id]);
  };
  const handleStep2SelectedIds = (item) => {
    // Set the state to an array containing only the new ID
    setStep2SelectedIds([item.id]);
  };

  const tokenSelector = useSelector((state) => state.rootReducer?.user?.token);

  useEffect(() => {
    // questions_onboard();
    dispatch(url_action(location.pathname));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    search_onboard_questions();

    // eslint-disable-next-line
  }, [searchQuery, location.pathname]);

  const handle = (id) => {
    if (onbordingStep === 3) {
      navigate("/playground");
      dispatch(onBoarding());
    } else {
      if (!id && stepOneSelectedId === "" && onbordingStep === 1) {
        errorMessage("Please select one ");
      } else {
        setOnbordingStep(onbordingStep + 1);
      }
    }
  };

  // const questions_onboard = () => {
  //   // Create a new FormData object

  //   // Make the API call using the FormData object
  //   commonApi
  //     .questions_onboard("", tokenSelector)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setOnboardingQuestion(res.questions);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Err", err);
  //       return { type: "error", message: err.message };
  //     });
  // };

  const onboard = (scndid, firstId) => {
    const formData = new FormData();

    // Append the fields from the data object to the FormData object
    formData.append("grade", firstId);
    formData.append("college_preference", scndid);

    commonApi
      .onboard(formData, tokenSelector)
      .then((res) => {
        if (res.status === 200) {
          if (!grade) {
            handle(firstId, scndid);
          }
        }
      })
      .catch((err) => {
        console.log("Err", err);
        return { type: "error", message: err.message };
      });
  };

  const search_onboard_questions = () => {
    const formData = new FormData();

    formData.append("search", searchQuery);

    commonApi
      .search_onboard_questions(formData, tokenSelector)
      .then((res) => {
        if (res.status === 200 && res.success === true) {
          if (res.questions.length > 0) {

            const filteredQuestions = res?.questions.filter(
              (question) => !answers.some((answer) => answer.id === question.id)
            );

            if (filteredQuestions) {
              setSuggestions(filteredQuestions);
            }

            setDropdownVisible(true);


          } else {
            setDropdownVisible(false);
          }
        }
      })
      .catch((err) => {
        console.log("Err", err);
        return { type: "error", message: err.message };
      });
  };


  const handleOpenModel = (item) => {
    if (item.category === "sportsmanship") {
      setQuestionDetail(item);
      setModalShow1(true);
      // setAnswers(item)
    } else {
      setQuestionDetail(item);
      setModalShow(true);
      // setAnswers(item)

    }

    if (!step3to6SelectedIds.includes(item.id)) {
      // If not, push the ID into the array
      save_question(item.id);
    }
  };

  useEffect(() => {
    if (grade) {
      setStep1SelectedIds([grade]);
      setStepOneSelectedId(grade);
      onboard("", grade);
    }
    // eslint-disable-next-line
  }, [grade]);

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    if (value.trim() !== "") {
      search_onboard_questions();
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (value) => {

    setSearchQuery('')
    setSuggestions([])
    setDropdownVisible(false)

    // const selected = suggestions.find((question) => question.title === value);
    setSelectedQuestion(value);

    if (value) {
      handleOpenModel(value);
    }
  };

  const step1 = () => {
    return (
      <div className="competitive-boxes">
        {FirstStepList.map((item, index) => (
          <div
            className={`competitive-box ${step1SelectedIds.includes(item.id) ? "active_tab" : ""
              }`}
            key={index}
            onClick={() => {
              setStepOneSelectedId(item.id);
              handle(item.id);
              onboard(stepTwoSelectedId, item.id);
              handleStep1SelectedIds(item);
            }}
          >
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    );
  };
  const step2 = () => {
    return (
      <div className="competitive-boxes">
        {ScndStepList.map((item, index) => (
          <div
            className={`competitive-box ${step2SelectedIds.includes(item.id) && "active_tab"
              }`}
            key={index}
            onClick={() => {
              setStepTwoSelectedId(item.id);
              onboard(item.id, stepOneSelectedId);
              handleStep2SelectedIds(item);
            }}
          >
            <span>{item.title}</span>
          </div>
        ))}
      </div>
    );
  };

  const step3 = (item, index) => {
    return (
      <div
        onClick={() => {
          // Step3to6SelectedIds(item.id);
          handleOpenModel(item);
        }}
        className={`course-section ${"active"
          }`}
        key={index}
      >
        {item.title}
      </div>
    );
  };

  const save_question = (question_id) => {
    const formData = new FormData();

    formData.append("question_id", question_id);
    formData.append("label", "playground");

    commonApi
      .save_question(formData, tokenSelector)
      .then((res) => {
        if (res.status === 200) {
          handleStep3to6SelectedIds(question_id);
        }
      })
      .catch((err) => {
        console.log("Err", err);
        return { type: "error", message: err.message };
      });
  };


  const handleDataPush = (newData, answerData,) => {

    const updatedAnswers = [...answers]; // Create a copy of the answers state

    // Check if array has any items
    if (updatedAnswers.length > 0) {
      // Find if the item with the same id already exists
      const existingData = updatedAnswers.find(item => item.id === newData.id);

      if (existingData) {
        // Check if the answers array exists and has length
        if (existingData.answers && existingData.answers.length > 0) {
          // Check if the answers array already contains the answer with the same answer_id
          const answerIndex = existingData.answers.findIndex(ans => ans.id === answerData.id);

          if (answerIndex !== -1) {
            // If answer with the same answer_id exists, replace it
            existingData.answers[answerIndex] = answerData;
          } else {
            // If answer_id does not exist, push the new answer
            existingData.answers.push(answerData);
          }
        } else {
          // If the answers array is empty or does not exist, push the first answer
          existingData.answers = [answerData];
        }
      } else {
        // If the item doesn't exist, add the answer to the answers array and push the whole object
        newData.answers.push(answerData);
        updatedAnswers.push(newData);
      }
    } else {
      // If the array has no items, push the answer to the answers array and add the whole object
      newData.answers.push(answerData);
      updatedAnswers.push(newData);
    }

    setAnswers(updatedAnswers);
  }



  //   const deleteAnswer = (questionId, answerId)=> {

  //     console.log(answers, 'kkkkkkkkkkkkkkkkkkkkkkkk')
  //     console.log(questionId, 'questionIdquestionId')
  //     console.log(answerId, 'answerIdanswerId')
  //     // Find the object in the array that matches the given question id
  //     const existingDataIndex = answers.findIndex(item => item.id === questionId);

  //     if (existingDataIndex !== -1) {
  //       alert('')
  //         const existingData = answers[existingDataIndex];

  //         // Check if answers array exists and has length
  //         if (existingData.answers && existingData.answers.length > 0) {
  //           alert('1')
  //             // Find the index of the answer that matches the given answerId
  //             const answerIndex = existingData.answers.findIndex(answer => answer.id === answerId);

  //             if (answerIndex !== -1) {
  //               alert('2')
  //                 // Remove the answer from the answers array
  //                 existingData.answers.splice(answerIndex, 1);

  //                 // After deleting, check if answers array is empty
  //                 if (existingData.answers.length === 0) {
  //                   alert('3')
  //                     // Remove the entire data object if no answers are left
  //                     answers.splice(existingDataIndex, 1);
  //                 }
  //             }
  //         } 
  //     } 
  // }



  return (
    <>
      <div className="on-boarding-screens">
        <div className="leadership-block green-screen">
          <div className="leadership-block-contain">
            <div className="leadership-block-holder">
              <div className="logo-holder">
                <img src={pathFinderLogo} alt="path-finder-logo" />
              </div>
            </div>
            <div className="leadership-txt-contain">
              <h3>
                {" "}
                {onbordingStep === 1
                  ? "Let’s start with the basics"
                  : onbordingStep === 2
                    ? "Share your dream schools"
                    :
                    "Share your dream schools"
                  // : onbordingStep === 4
                  // ? "Share more about your Scholarship experience"
                  // : onbordingStep === 5
                  // ? "Share more about your Citizenship experience"
                  // : onbordingStep === 6
                  // ? "Share more about your Sportsmanship experience"
                  // : "Leadership"
                }
              </h3>
              {/* {onbordingStep !== 1 && <h3>Share your dream schools</h3>} */}
            </div>
            <div className="next-line-holder">
              <span className={onbordingStep === 1 && "active"}></span>
              <span className={onbordingStep === 2 && "active"}></span>
              <span className={onbordingStep === 3 && "active"}></span>
              {/* <span className={onbordingStep === 4 && "active"}></span>
              <span className={onbordingStep === 5 && "active"}></span>
              <span className={onbordingStep === 6 && "active"}></span> */}
            </div>

            <div className="skip-holder mt-4">
              <h4>
                {" "}
                {onbordingStep === 1
                  ? "What year are you in high school?"
                  : onbordingStep === 2
                    ? "What type of colleges are you interested in?"
                    : "What type of colleges are you interested in?"}
              </h4>

              <strong
                className="as-link"
                onClick={() => {
                  if (onbordingStep !== 1) {
                    handle();
                  }
                }}
              >
                {onbordingStep !== 1 && " Skip"}
              </strong>
            </div>

            <>
              {onbordingStep === 1 && step1()}
              {onbordingStep === 2 && step2()}
              {onbordingStep !== 1 && onbordingStep !== 2 && (
                <div
                  className="autocomplete-search-container"
                  style={{
                    maxWidth: "500px",
                    margin: "auto",
                    position: "relative",
                  }}
                >
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="Search for colleges you would like to attend"
                      value={searchQuery}
                      onChange={(e) => handleSearchChange(e.target.value)} // Update query on user input
                      aria-label="Search"
                    />
                  </InputGroup>

                  {isDropdownVisible && suggestions.length > 0 && (
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                        marginTop: "0px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        position: "absolute",
                        width: "100%",
                        maxHeight: "200px", // Set maximum height for scroll
                        overflowY: "auto", // Enable vertical scrolling
                        zIndex: 1000,
                      }}
                    >
                      {suggestions.map((suggestion) => (
                        <li
                          key={suggestion.id}
                          onClick={() => handleSelectSuggestion(suggestion)}
                          style={{
                            padding: "10px",
                            borderBottom: "1px solid #ddd",
                            cursor: "pointer",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          {suggestion.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}

              <div className="course-slection-block mt-3">
                {onbordingStep === 3 &&
                  answers?.length > 0 &&
                  answers?.map((step, index) =>
                    step3(step, index)
                  )}

                {/* {onbordingStep === 4 &&
                  onboardingQuestion &&
                  onboardingQuestion?.scholarship?.map((step, index) =>
                    step3(step, index)
                  )} */}
                {/* {onbordingStep === 5 &&
                  onboardingQuestion &&
                  onboardingQuestion?.citizenship?.map((step, index) =>
                    step3(step, index)
                  )} */}
                {/* {onbordingStep === 6 &&
                  onboardingQuestion &&
                  onboardingQuestion?.sportsmanship?.map((step, index) =>
                    step3(step, index)
                  )} */}
              </div>
            </>
          </div>
        </div>
        <Footer
          setOnbordingStep={setOnbordingStep}
          onbordingStep={onbordingStep}
          handle={handle}
        />
      </div>
      {modalShow && (
        <QuetionsModal
          show={modalShow}
          onHide={() => { setModalShow(false) }}
          showData={questionDetail}
          answersArray={handleDataPush}
          // deleteAnswer={deleteAnswer}
          // answers={answers}
          answers={answers}
          // Step3to6SelectedIds={handleStep3to6SelectedIds}
          // setSelectedQuestionArray={setSelectedQuestionArray}
          selectedQuestionArray={selectedQuestionArray}
        />
      )}
      {modalShow1 && (
        <SportsmanshipModal
          show={modalShow1}
          onHide={() => {
            setModalShow1(false);
          }}
          showData={questionDetail}
          answersArray={handleDataPush}
          answers={answers}
          // Step3to6SelectedIds={handleStep3to6SelectedIds}
          setSelectedQuestionArray={setSelectedQuestionArray}
          selectedQuestionArray={selectedQuestionArray}
        />
      )}
    </>
  );
};

export default Onboarding;
