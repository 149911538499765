import React, { useState } from "react";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { group9_icon, redBucket } from "../../constant/images";
import DropdownDashboard from "./DropdownDashboard";
import { convertObjectToString, scholarshipDropdown } from "../../constant/commonUtils";
import TooltipButton from "../Dashoard/TooltipButton";
import SpinnerLoader from "../../webLoader/SpinnerLoader";
// import TooltipButton from "./TooltipButton";
const DashboardTab = ({
  tabType,
  setTabtype,
  tabs,
  setSearhQuery,
  searchQuery,
  dropdownListing,
  dropdownSelected,
  dropdownSelectedId,
  nameClasss,
  userAction,
  listing,
  type,
  userType,
  getTitleById,
  handleDropdownChange,
  styleComponent,
  group,
  TagsView,
  handle,
  spinnerLoader
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  const ListingOfPages = () => {
    return (
      <>
        <div className="d-flex flex-wrap align-items-center gap-3 p-2 justify-content-between dashboard-name-section">
          <ul className="nav nav-tabs border-0 gap-3">
            {tabs.map((item, idx) => (
              <li
                className="nav-item"
                key={idx}
                onClick={() => {
                  setTabtype(item.id);
                }}
              >
                <a
                  className={`nav-link ${item.id === tabType && "active"
                    } p-0 as-link`}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
          <div className="tab-dropdown-listing d-flex gap-3 align-items-center justify-content-center">
            {dropdownListing && dropdownListing.length > 0 && (
              <DropdownDashboard
                dropdownListing={dropdownListing}
                dropdownSelected={dropdownSelected}
                dropdownSelectedId={dropdownSelectedId}
                placeholder="ddd"
                itemName={"title"}
              />
            )}
            {setSearhQuery && (
              <input
                type="text"
                value={searchQuery}
                className="form-control w-auto"
                placeholder="Search"
                onChange={(e) => {
                  setSearhQuery(e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div class="dashboard-label p-0">
          <form className="mt-3 mb-3">
              <div class="form-group">

                <div class="row g-3">
                  <div class="col">Name</div>
                  <div className="col d-flex justify-content-end align-items-end">
                    {dropdownSelectedId === "all" && type === 'universities' &&
                      'Group'}
                  </div>


                  <div className="col d-flex justify-content-end align-items-end">
                    {((type === 'universities' || type === 'scholarship') && tabType === 'select')
                      ? 'Action'
                      : (tabType === 'select' && type === 'journey') &&
                      'Participation Frequency'
                    }
                  </div>


                  {/* <div class="col"></div> */}
                </div>

              </div>
            
          </form>
        </div>

        <div className="dashboard-card">
          <div className="dashboard-listings position-relative" style={styleComponent}>
          {
          spinnerLoader && <SpinnerLoader color={'white'}/>
          }
            <div className="row">
         {""}
            </div>
            {listing.length > 0 ? (
              listing.map((item, idx) => (
                <div
                  className="row g-3 border-bottom py-2 align-items-center"
                  key={idx}
                >
                  <div className="col d-flex gap-3 align-items-end">
                    <div className="content-dashboardtab">
                      <div className="hamid d-flex gap-1 flex-column">
                        <h3>{item.title}</h3>
                        {item?.answers?.length > 0 && (
                          <div>
                            {item &&
                              item?.answers?.length > 0 &&
                              item?.answers?.map((answer, index) => (
                                <div
                                  index={index}
                                  className="circle-icon-holder circle-icon-child-holder"
                                >
                                  <span>{answer.answer}</span>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>



                      <TooltipButton
                        icon={group9_icon}
                        tooltipText={item.description}
                      />

                    </div>

                  </div>



                  {group === "universities" && dropdownSelectedId === "all" && (
                    <div className="col d-flex justify-content-center align-items-end">
                      <div className="m-0">
                        {getTitleById(item.group)}
                      </div>
                    </div>
                  )}

                  {userType === "user" && item.selected && (
                    <div className="col d-flex justify-content-end align-items-end">
                      <DropdownDashboard
                        dropdownListing={scholarshipDropdown}
                        dropdownSelectedId={item}
                        handleDropdownChange={handleDropdownChange}
                        placeholder="ddd"
                        itemName={"title"}
                      />
                    </div>
                  )}
                  <div className="col d-flex justify-content-end align-items-center gap-2">
                    {item &&
                      item?.answers?.length > 0 &&
                      <div className="m-0">
                        {item &&
                          item?.answers?.length > 0 &&
                          item?.answers?.map((answer, index) => (
                            <div index={index}>
                              {convertObjectToString(answer)}
                            </div>
                          ))}
                      </div>}
                    <div className="dashboard-buttons d-flex align-items-center gap-3">



                     

                      {type !== "dashboard" && (
                        <button
                          type="button"
                          className="custm-selct-icon-btn btn btn-primary btn-md mt-0 mr-5"
                          onClick={() => userAction(item, "select")}
                        >
                          {(item.answers && item.answers.length > 0) ||
                            (type === "universities" && userType === "admin") ||
                            type === "news"
                            ? "Edit"
                            : item.selected
                              ? "Deselect"
                              : "Select"}
                        </button>
                      )}
                       {userType !== "admin" &&
                        type !== "universities" &&
                        type !== "scholarship" && tabType !== 'select' && (
                          <div
                            className="m-0 as-link"
                            onClick={() => userAction(item, "save")}
                          >
                            {item?.saved ? (
                              <FaBookmark size={24} />
                            ) : (
                              <FaRegBookmark size={24} />
                            )}
                          </div>
                        )}

                      {userType === "admin" && type == "news" && (
                        <div
                          className="m-0 as-link"
                          onClick={() => userAction(item, "delete")}
                        >
                          <img src={redBucket} alt="Delete" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center no-record-section">
                <p className="font-weight-bold text-muted">No record found</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const ListingofTags = () => {
    return (
      <>
        <div className="d-flex flex-wrap align-items-center gap-3 p-2 justify-around dashboard-name-section">
          {dropdownListing && dropdownListing.length > 0 && (
            <DropdownDashboard
              dropdownListing={dropdownListing}
              dropdownSelected={dropdownSelected}
              dropdownSelectedId={dropdownSelectedId}
              placeholder="ddd"
              itemName={"title"}
            />
          )}
        </div>


        <div class="dashboard-label p-0">
          <form className="mt-3 mb-3">
              <div class="form-group">
                <div class="row g-3">
                  <div class="col">Name</div>
                  <div class="col"></div>
                  <div class="col"></div>
                  <div class="col"></div>
                </div>

              </div>
            
          </form>
        </div>
        <div className="dashboard-listings position-relative" style={styleComponent}>
        <div className="row">
          {
          spinnerLoader && <SpinnerLoader color={'white'}/>
          }
            </div>

          {listing.length > 0 ? (
            listing.map((item, idx) => (
              <div
                className="row g-3 border-bottom py-2"
                key={idx}
              >
                <div className="col d-flex gap-3 align-items-end">
                  <div className="content-dashboardtab">
                    <h3>{item.title}</h3>
                    <TooltipButton
                      icon={group9_icon}
                      tooltipText={item.description}
                    />
                  </div>
                </div>
                <div className="col d-flex gap-3 align-items-end">
                  {TagsView(item)}
                </div>

              </div>
            ))
          ) : (
            <div className="text-center no-record-section">
              <p className="font-weight-bold text-muted">No record found</p>
            </div>
          )}
        </div>


        {/* <div className="table-responsive p-2">
          <table className="table">
            <thead>
              <tr>
                <div class="dashboard-label p-0">
                  <form className="mt-3 mb-3">
                    <div class="form-group">
                      <label for="username">Name</label>
                    </div>
                  </form>
                </div>
              </tr>
            </thead>
            <tbody
        
              className={`dashboard-listings`}
              style={styleComponent}
             
            >
              {listing.length > 0 ? (
                listing.map((item, idx) => (
                  <tr
                    key={idx}
                    className="border-bottom d-flex justify-content-between"
                  >
                    <td className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                      <div className="content-dashboardtab">
                        <h3>{item.title}</h3>
                      
                        <TooltipButton
                          icon={group9_icon}
                          tooltipText={item.description}
                        />
                      </div>
                    </td>

                    {TagsView(item)}
                  </tr>
                ))
              ) : (
                <div className="text-center no-record-section">
                  <p className="font-weight-bold text-muted">No record found</p>
                </div>
              )}
              {listing.length > 0 && (
                <td className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                  <button
                    type="button"
                    class="custm-selct-icon-btn btn btn-primary btn-md m-0"
                    onClick={() => {
                      handle();
                    }}
                  >
                    Submit
                  </button>
                </td>
              )}
            </tbody>
          </table>
        </div> */}
      </>
    );
  };
  const ListingofProfile = () => {
    return (
      <>
        <div class="dashboard-label p-0">
          <form className="mt-3 mb-3">

              <div class="form-group">
                <div class="row g-3">
                  <div class="col">Name</div>
                  <div class="col">Email</div>
                  <div class="col">Payment Status </div>
                  <div class="col text-end">Detail</div>
                </div>
              </div>
            
          </form>
        </div>
        <div className="dashboard-listings position-relative" style={styleComponent}>
         
         <div className="row">
          {
          spinnerLoader && <SpinnerLoader color={'white'}/>
          }
            </div>

          {listing.length > 0 ? (
            listing.map((item, idx) => (
              <div
                className="row g-3 border-bottom py-2"
                key={idx}
              >
                <div className="col d-flex gap-3">
                  <div className="content-dashboardtab">
                    <h3>{item.name}</h3>
                  </div>
                </div>
                <div className="col d-flex gap-3  d-flex justify-content-start align-items-end">
                  <div className="content-dashboardtab">
                    <h3>{item.email}</h3>
                  </div>
                </div>
                <div className="col d-flex gap-3 d-flex justify-content-center align-items-center">
                  <div className="content-dashboardtab">
                    <h3>{item.payment_status}</h3>
                  </div>
                </div>
                <div className="col d-flex gap-3  d-flex justify-content-end align-items-center pe-4">
                  <button
                    type="button"
                    class="custm-selct-icon-btn btn btn-primary btn-md m-0"
                    onClick={() => {
                      userAction(item, "select");
                    }}
                  >
                    Details
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center no-record-section">
              <p className="font-weight-bold text-muted">No record found</p>
            </div>
          )}
        </div>

        {/* <table className="table">
            <thead>
              <tr className="border-bottom d-flex justify-content-between">
                <th className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                  <div className="content-dashboardtab">
                    <h4>Name</h4>
                  </div>
                </th>
                <th className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                  <div className="content-dashboardtab">
                    <h4>Email</h4>
                  </div>
                </th>
                <th className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                  <div className="content-dashboardtab">
                    <h4>Payment Status</h4>
                  </div>
                </th>
                <th className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                  <div className="content-dashboardtab">
                    <h4>Detail</h4>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={`dashboard-listings`} style={styleComponent}>
              {listing.length > 0 ? (
                listing.map((item, idx) => (
                  <tr
                    key={idx}
                    className="border-bottom d-flex justify-content-between"
                  >
                    <td className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                      <div className="content-dashboardtab">
                        <h3>{item.name}</h3>
                      </div>
                    </td>
                    <td className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                      <div className="content-dashboardtab">
                        <h3>{item.email}</h3>
                      </div>
                    </td>
                    <td className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                      <div className="content-dashboardtab">
                        <h3>{item.payment_status}</h3>
                      </div>
                    </td>
                    <td className="d-flex gap-3 align-items-end text-muted border-0 pb-3 pt-3">
                      <button
                        type="button"
                        class="custm-selct-icon-btn btn btn-primary btn-md m-0"
                        onClick={() => {
                          userAction(item, "select");
                        }}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="text-center no-record-section">
                  <p className="font-weight-bold text-muted">No record found</p>
                </div>
              )}
            </tbody>
          </table> */}

      </>
    );
  };

  return (
    <div className="p-4 bg-white rounded-3 shadow-sm dashboard-tab-section">
      {type !== "profile" && type !== "settings" && ListingOfPages()}

      {type === "profile" && ListingofProfile()}

      {type === "settings" && ListingofTags()}
    </div>
  );
};

export default DashboardTab;
