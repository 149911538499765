
const payment_error_message ='Please purchase one package first';
const data_error = 'No data found';





export{
    payment_error_message,
    data_error
}