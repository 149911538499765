import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  Tab,
  Form,
  OverlayTrigger,
  Popover,
  Table,
  Dropdown,
} from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import SpinnerLoader from "../../webLoader/SpinnerLoader";
import {
  cardlisting,
  scholarshipDropdown,
  statusChange,
  styleComponent,
  universityDropDownList,
} from "../../constant/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { data_error } from "../../constant/WebText";
import AddCollege from "../Modals/AddCollege";
import { height_action } from "../../Redux/HeightAction";
import { scroll_action } from "../../Redux/ScrollableAction";
import commonApi from "../../CommonApi/CommonServices";
import { successMessage } from "../../Errors/Toast";
import Calendar from "../Dashoard/Calander";
import ViewAll from "../Dashoard/ViewAll";
import MaskUser from "../Dashoard/MaskUser";
import DashboardTab from "./DashboardTab";
import MiniCard from "../Dashoard/MiniCard";
import { frame3_icon } from "../../constant/images";

const Universities = (props) => {
  const {
    para,
    searchQuery,
    setSearchQuery,
    universityDropDownList,
    dropdownId,
    setdropdownId,
    spinnerLoader
  } = props;
  const [modalShow2, setModalShow2] = useState(false);
  const [selectedUniData, setSelectedUniData] = useState({});
  const userSelector = useSelector((state) => state.rootReducer);
  const adminSelector = useSelector((state) => state.rootReducer);

  const dispatch = useDispatch();

  const { universityListing, tabtype, setTabtype } = props;
  const tokenSelector = useSelector((state) => state.rootReducer?.user?.token);

  // const selectedTrueArray = universityListing?.filter((item) => item.selected);
  // const selectedFalseArray = universityListing?.filter(
  //   (item) => !item.selected
  // );

  const [selectedFalseArray, setSelectedFalseArray] = useState([]);
  const [selectedTrueArray, setSelectedTrueArray] = useState([]);

  useEffect(() => {
    if (universityListing?.length > 0) {
      const unSelectedData = universityListing.filter((item) => !item.selected);
      setSelectedFalseArray(unSelectedData);

      const selectedData = universityListing.filter((item) => item.selected);
      setSelectedTrueArray(selectedData);
    } else {
      // Clear arrays when universityListing is empty
      setSelectedFalseArray([]);
      setSelectedTrueArray([]);
    }
  }, [universityListing, searchQuery]);

  console.log(
    selectedFalseArray,
    "selectedFalseArrayselectedFalseArrayselectedFalseArray"
  );
  console.log(
    selectedTrueArray,
    "selectedTrueArrayselectedTrueArrayselectedTrueArray"
  );

  useEffect(() => {
    if (tabtype === "list") {
      dispatch(height_action(selectedFalseArray, "list"));
    } else if (tabtype === "select") {
      dispatch(height_action(selectedTrueArray, "select"));
    }
    // eslint-disable-next-line
  }, [tabtype, universityListing]);

  const popover = (description) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>{description}</Popover.Body>
      </Popover>
    );
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleShowModel = (item) => {
    if (item) {
      setSelectedUniData(item);
      setModalShow2(true);
    } else {
      setModalShow2(true);
    }
  };

  const getTitleById = (id) => {
    // Find the object in ScndStepList where id matches the input id
    const matchingObject = universityDropDownList.find(
      (item) => item.id === id
    );

    // If a matching object is found, return its title
    if (matchingObject) {
      return matchingObject.title;
    } else {
      return "Title not found"; // Return a default value if no matching title is found
    }
  };

  useEffect(() => {
    setSearchQuery("");
    // eslint-disable-next-line
  }, [tabtype]);

  const reduxHeight = useSelector((state) => state.height_reducer);
  const dataLength = reduxHeight?.data?.data;

  const containerRef = useRef(null);
  const containerRef2 = useRef(null);
  const container = containerRef.current;
  const container2 = containerRef2.current;

  useEffect(() => {
    if (dataLength && (container || container2)) {
      setTimeout(() => {
        if (
          (container && container.scrollHeight > container.clientHeight) ||
          (container2 && container2.scrollHeight > container2.clientHeight)
        ) {
          dispatch(scroll_action(true));
        } else {
          dispatch(scroll_action(false));
        }
      }, 100);
    }
    // eslint-disable-next-line
  }, [dataLength, container, container2, tabtype, universityListing]);

  const status_change_scholarship = (status, id) => {
    const formData = new FormData();

    formData.append("subject_id", id);
    formData.append("status", status);
    formData.append("type", "university");

    commonApi
      .status_change_colleges_scholarship(formData, tokenSelector)
      .then((res) => {
        if (res.status === 200 && res.success) {
          successMessage(res.message);
        }
      })
      .catch((err) => {
        console.log("Err", err);
        return { type: "error", message: err.message };
      });
  };

  const handleDropdownChange = (id, selectedValue) => {
    // const updatedArray = selectedTrueArray.map((item) => {
    //   if (item.id === id) {
    //     return { ...item, status: selectedValue };
    //   }
    //   return item;
    // });
    // setSelectedTrueArray(updatedArray);

    const updateArray = statusChange(selectedValue, selectedTrueArray, id);
    setSelectedTrueArray(updateArray);
    status_change_scholarship(selectedValue, id);
  };

  const handleUserAction = (item, type) => {
    if (userSelector?.user?.user_type !== "admin") {
      if (type === "select") {
        props.select_university(item.id);
      }
    } else {
      handleShowModel(item);
    }
  };

  return (
    <>
      {/* {props.spinnerLoader && <SpinnerLoader />} */}
      {/* <div className="row main-tabs-block position-relative">
        <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 main-tabs-contain">
          <div className="universities-tab">
            <div className="main-search-blocks universities-search">
              <div className="search-input-holder">
                <Form.Control
                  size="lg"
                  type="search"
                  className="search-input"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            {userSelector?.user?.user_type !== "admin" ? (
              <Tabs
                id="controlled-tab-example"
                activeKey={tabtype}
                onSelect={(k) => setTabtype(k)}
                className="mb-2"
              >
                <Tab
                  eventKey="list"
                  title={
                    <div>
                      <span>List</span>
                    </div>
                  }
                >
                  <div className="our-custom-tab-content">
                    <h4>
                      Highly Selective Liberal Arts Colleges and Ivy League
                      Universities{" "}
                    </h4>
                    <p>{para}</p>
                  </div>
                  {selectedFalseArray.length > 0 ? (
                    <div className="all-tabs-table-holder table-responsive">
                      <Table className="alltabs-list-table">
                        <thead>
                          <tr>
                            <th>
                              {selectedFalseArray?.length > 0 &&
                                "University or College"}
                            </th>
                            <th>
                              {selectedFalseArray?.length > 0 &&
                                props.dropdownId === "all" &&
                                "Group Name"}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                          <tbody className="universities-responsive" ref={containerRef}>
                            {selectedFalseArray &&
                              selectedFalseArray.length > 0 &&
                              selectedFalseArray.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="circle-icon-holder">
                                      <h4>
                                        {item.title}
                                        <OverlayTrigger
                                          trigger={["hover", "focus"]}
                                          placement="top"
                                          style={{
                                            position: "fixed",
                                            transform:
                                              "translateY(-340px)!important",
                                          }}
                                          overlay={popover(item.description)}
                                        >
                                          <span>
                                            <AiOutlineExclamationCircle />
                                          </span>
                                        </OverlayTrigger>
                                      </h4>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="bookmark-group">
                                      <h4>
                                        {" "}
                                        {props.dropdownId === "all" &&
                                          getTitleById(item.group)}
                                      </h4>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="bookmark-icon-holder">
                                      <button
                                        type="button"
                                        className="custm-selct-icon-btn btn btn-primary btn-md"
                                        onClick={() => {
                                          props.select_university(item.id);
                                        }}
                                      >
                                        Select
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                      </Table>
                    </div>
                  ) : (
                    props.spinnerLoader && <div className="no_data_found"></div>
                  )}
                  {!props.spinnerLoader && selectedFalseArray.length === 0 && (
                    <div className="text-center empty-text-holder">
                      <h3>{data_error}</h3>
                    </div>
                  )}
                </Tab>
                <Tab
                  eventKey="select"
                  title={
                    <div>
                      <span>Selected</span>
                    </div>
                  }
                >
                  <div className="our-custom-tab-content our-custom-tab-content-one">
                    <h4>
                      Highly Selective Liberal Arts Colleges and Ivy League
                      Universities{" "}
                    </h4>
                    <p>{props.para}</p>
                    {selectedTrueArray.length > 0 ? (
                      <div className="table-responsive all-tabs-table-holder">
                        <Table className="alltabs-list-table">
                          <thead>
                            <tr>
                              <th>
                                {selectedTrueArray?.length > 0 &&
                                  "University or College"}
                              </th>
                              <th>
                                {selectedTrueArray?.length > 0 &&
                                  props.dropdownId === "all" &&
                                  "Group Name"}
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                            <tbody className="universities-responsive" ref={containerRef2}>
                              {selectedTrueArray &&
                                selectedTrueArray.length > 0 &&
                                selectedTrueArray.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="circle-icon-holder">
                                        <h4>
                                          {item.title}
                                          <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="top"
                                            overlay={popover(item.description)}
                                            style={{
                                              position: "fixed",
                                              transform:
                                                "translateY(-340px)!important",
                                            }}
                                          >
                                            <span>
                                              <AiOutlineExclamationCircle />
                                            </span>
                                          </OverlayTrigger>
                                        </h4>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="bookmark-group">
                                        <h4>
                                          {" "}
                                          {props.dropdownId === "all" &&
                                            getTitleById(item.group)}
                                        </h4>
                                      </div>

                                    </td>
                                    <td>
                                     
                                      <div className="bookmark-icon-holder">
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          {scholarshipDropdown.find((scholar) => scholar.id === item.status)?.title}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {scholarshipDropdown.map((items, idx) => (
                            <Dropdown.Item
                              key={idx}
                              onClick={() => {
                                handleDropdownChange(item.id, items.id);
                              }
                            }
                            >
                              {items.title}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                          </div>
                                    </td>
                                    <td>
                                      <div className="bookmark-icon-holder">
                                        <button
                                          type="button"
                                          className="custm-selct-icon-btn btn btn-primary btn-md"
                                          onClick={() => {
                                            props.select_university(item.id);
                                          }}
                                        >
                                          DeSelect
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                        </Table>
                      </div>
                    ) : (
                      props.spinnerLoader && (
                        <div className="no_data_found"></div>
                      )
                    )}
                    {!props.spinnerLoader &&
                      selectedTrueArray &&
                      selectedTrueArray.length === 0 && (
                        <div className="text-center empty-text-holder">
                          <h3>{data_error}</h3>
                        </div>
                      )}
                  </div>
                </Tab>
              </Tabs>
            ) : (
              <div className="our-custom-tab-content">
                <h4>
                  Highly Selective Liberal Arts Colleges and Ivy League
                  Universities{" "}
                </h4>
                <p>{para}</p>
                {universityListing.length > 0 ? (
                  <div className="table-responsive all-tabs-table-holder">
                    <Table
                      className="alltabs-list-table" 
                    >
                      <thead>
                        <tr>
                          <th>
                            {universityListing.length > 0 &&
                              "University or College"}
                          </th>
                          <th>
                            {universityListing.length > 0 &&
                              props.dropdownId === "all" &&
                              "Group Name"}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="universities-responsive" style={{ height: "calc(100vh - 366px)" }} ref={containerRef}>
                      {universityListing &&
                            universityListing.length > 0 &&
                            universityListing.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="circle-icon-holder">
                                    <h4>
                                      {item.title}
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={popover(item.description)}
                                        style={{
                                          position: "fixed",
                                          transform:
                                            "translateY(-340px)!important",
                                        }}
                                      >
                                        <span>
                                          <AiOutlineExclamationCircle />
                                        </span>
                                      </OverlayTrigger>
                                    </h4>
                                  </div>
                                </td>
                                <td>
                                  <div className="bookmark-group">
                                    <h4>
                                      {" "}
                                      {props.dropdownId === "all" &&
                                        getTitleById(item.group)}
                                    </h4>
                                  </div>
                                </td>
                                <td>
                                  <div className="bookmark-icon-holder">
                                    <button
                                      type="button"
                                      className="custm-selct-icon-btn btn btn-primary btn-md"
                                      onClick={() => {
                                        handleShowModel(item);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                    </Table>
                  </div>
                ) : (
                  props.spinnerLoader && <div className="no_data_found"></div>
                )}
                {!props.spinnerLoader &&
                  selectedFalseArray &&
                  selectedFalseArray.length === 0 && (
                    <div className="text-center empty-text-holder">
                      <h3>{data_error}</h3>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div> */}

      <div className={"line-chart-main-container-user"}>
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-9 pe-4 progress-3">
              <DashboardTab
                tabs={
                  userSelector?.user?.user_type === "user"
                    ? [
                        { name: "List", id: "list" },
                        { name: "Selected", id: "select" },
                      ]
                    : [{ name: "List", id: "list" },]
                }
                setTabtype={setTabtype}
                tabType={tabtype}
                dropdownListing={universityDropDownList}
                dropdownSelected={setdropdownId}
                dropdownSelectedId={dropdownId}
                listing={
                  userSelector?.user?.user_type !== "admin"
                    ? tabtype === "list"
                      ? selectedFalseArray
                      : tabtype === "select" && selectedTrueArray
                    : universityListing
                }
                userAction={handleUserAction}
                type={"universities"}
                setSearhQuery={setSearchQuery}
                searchQuery={searchQuery}
                userType={userSelector?.user?.user_type}
                getTitleById={getTitleById}
                handleDropdownChange={handleDropdownChange}
                group={"universities"}
                nameClasss={"journey-listingsss"}
                styleComponent={styleComponent}
                spinnerLoader={spinnerLoader}
                // openModal={handleShowModel}
              />
            </div>

            <div className="col-md-3 col-sm-3 mb-4 ps-0 calander-deshboards">
              {/* {userSelector?.user?.user_type === 'user' && */}
              <div>
                <MiniCard
                  handleShowModel={handleShowModel}
                  cardlisting={
                    userSelector?.user?.user_type === "user"
                      ? [cardlisting[2]]
                      : [{ uperName: "Add Collage", img: frame3_icon }]
                  }
                />
              </div>
              {/* } */}
              <div className="mb-4">
                <Calendar />
              </div>
              <ViewAll />
              {userSelector?.user?.user_type === 'user' &&

              <MaskUser />
}
            </div>
          </div>
        </div>
      </div>

      {modalShow2 && (
        <AddCollege
          show={modalShow2}
          onHide={() => {
            setModalShow2(false);
            setSelectedUniData({});
          }}
          get_university={props.get_university}
          selectedUniData={selectedUniData}
        />
      )}

      {/* <CustomModal show={modalShow} onHide={() => setModalShow(false)} />
      <CustomModalOne show={modalShow1} onHide={() => setModalShow1(false)} /> */}
    </>
  );
};

export default Universities;
