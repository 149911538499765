import React, { useState } from "react";
import { womenImg, pathFinderLogo, menImg, notification_icon } from "../../constant/images";
import "../../assets/css/custom.css";
// import { Button } from "react-bootstrap";
import ViewProfile from "../Modals/ViewProfile";
import { useSelector } from "react-redux";
import DropdownTopBar from "./dropdownTopBar";
// import { Button } from "react-bootstrap";
// import { decryptData } from "../../Secure_data";

// const logout = () => {
//   localStorage.clear();
//   window.location.replace("/login");
// };

const Topbar = (props) => {
  const genderSelector = useSelector((state) => state.rootReducer);

  const [modalShow2, setModalShow2] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [genderofProfile, setGenderofProfile] = useState(
    // localStorage.getItem("gender") === "male" ? true : false
    genderSelector?.user?.gender === "male" ? true : false
  );
  // console.log(decryptData(localStorage.getItem("payment_status"),'ddddddddd'))

  // const [pricingData, setPricingData] = useState({
  //   grade: "",
  //   id: "",
  //   price: "",
  //   type: "",
  // });

  // const pricingSelector = useSelector(state=>state.payment_reducer)

  // const handle = () => {
  //   setPricingData({
  //     grade: pricingSelector?.data?.grade,
  //     id: pricingSelector?.data?.id,
  //     price: pricingSelector?.data?.price,
  //     type: pricingSelector?.data?.type,
  //   })
  // };

  // console.log(pricingData,"pricingSelector?.grade ")
  const items = ['Item 1', 'Item 2', 'Item 3'];

  const handleSelect = () => {
    // alert(`Selected: ${item}`);
  };

  return (
    // <div className="main-top-bar" style={{ minHeight: "72px" }}>

    //   <div className="logo-holder">
    //     <img src={pathFinderLogo} alt="path-finder-logo" />
    //   </div>

    //   <div className="img-holder">
    //     {genderSelector?.user?.user_type !== "admin" && (
    //       <div className="custom-profile-btn">
    //         <img
    //           src={genderofProfile ? menImg : womenImg}
    //           alt="women"
    //           onClick={() => {
    //             setModalShow2(true);
    //           }}
    //         />
    //       </div>
    //     )}
    //     <div
    //       className="menu_Icon"
    //       onClick={() => {
    //         props.setMenuToggle(!props.menuToggle);
    //       }}
    //     >
    //       <i className="fa-solid fa-bars"></i>
    //     </div>
    //     {/* <Button type="button" variant="outline-success" 
    //     onClick={()=>handle()}
    //     >
    //       Pricing Data
    //     </Button> */}
    //   </div>

    //   {modalShow2 && (
    //     <ViewProfile
    //       show={modalShow2}
    //       onHide={() => setModalShow2(false)}
    //       setGenderofProfile={setGenderofProfile}
    //       genderofProfile={genderofProfile}
    //       type="OwnProfile"
    //     />
    //   )}
    // </div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="#">
        <img src={pathFinderLogo} alt="Logo" />
      </a>
      <div
        className="menu_Icon"
        onClick={() => {
          props.setMenuToggle(!props.menuToggle);
        }}
      >
        <i className="fa-solid fa-bars"></i>
      </div>
      <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="#">
              <img
                src={notification_icon}
                alt="Notifications"
                style={{ width: 24, height: 24 }}
              />
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {genderSelector?.user?.user_type !== "admin" && (
                <img
                  onClick={() => {
                    setModalShow2(true);
                  }}
                  src={genderofProfile ? menImg : womenImg}
                  alt="Profile"
                  className="rounded-circle"
                  style={{ width: 30, height: 30 }}
                />
              )}
              Olivia Brooks
              <i className="fa-solid fa-chevron-down" style={{ color: '#85888a' }}
                onClick={() => { setShowDropdown(!showDropdown) }}
              ></i>
            </a>
            {showDropdown &&
              <DropdownTopBar items={items} onSelect={handleSelect}
                setIsOpen={setShowDropdown} isOpen={showDropdown} />}
          </li>
        </ul>
      </div>
      {modalShow2 && (
        <ViewProfile
          show={modalShow2}
          onHide={() => setModalShow2(false)}
          setGenderofProfile={setGenderofProfile}
          genderofProfile={genderofProfile}
          type="OwnProfile"
        />
      )}
    </nav>


  );
};

export default Topbar;
