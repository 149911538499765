import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Logout } from "../../Redux/LoginAction";
import {
  dashboard_icon,
  journey_icon,
  logout_icon,
  profile_icon,
  Scholarship_icon,
  story_icon,
  universities_icon,
} from "../../constant/images";

const ResponsiveSidebar = (props) => {
  const location = useLocation();
  const adminSelector = useSelector((state) => state.rootReducer);
  const dispatch = useDispatch();

  const [activeMenu, setActiveMenu] = useState(
    location?.pathname === "/journey" &&
      adminSelector?.user?.user_type === "admin"
      ? 1
      : location?.pathname === "/journey" &&
        adminSelector?.user?.user_type === "user"
      ? 2
      : location?.pathname === "/playground" &&
        adminSelector?.user?.user_type === "user"
      ? 1
      : location?.pathname === "/universities" &&
        adminSelector?.user?.user_type === "admin"
      ? 2
      : location?.pathname === "/universities" &&
        adminSelector?.user?.user_type === "user"
      ? 3
      : location?.pathname === "/users" &&
        adminSelector?.user?.user_type === "admin"
      ? 3
        : location.pathname === '/news' &&
          adminSelector?.user?.user_type === 'admin'
      ? 4 
      : location.pathname === '/scholarship' &&
      adminSelector?.user?.user_type === 'user'
    ? 4  
      : location?.pathname === "/dashboard" && 0

  );

  const navigate = useNavigate();

  const Adminmenu = [
    { name: "Dashboard", img: dashboard_icon },
    { name: "Journey", img: journey_icon },
    { name: "Universities", img: universities_icon },
    { name: "Profile", img: profile_icon },
    { name: "News", img: profile_icon },
    { name: "Logout", img: logout_icon },
  ];
  const Usermenu = [
    { name: "Dashboard", img: dashboard_icon },
    { name: "Journey", img: journey_icon },
    { name: "Story", img: story_icon },
    { name: "Universities", img: universities_icon },
    { name: "Scholarship", img: Scholarship_icon },
    { name: "Logout", img: logout_icon },
  ];

  const handleLogout = () => {
    // dispatch(Logout())
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleItemClick = (index) => {
    setActiveMenu(index);
    switch (index) {
      case 0:
        navigate("/dashboard");
        break;
      case 1:
        navigate("/journey");
        break;
      case 2:
        navigate("/universities");
        break;
      case 3:
        navigate("/users");
        break;
      case 4:
        navigate("/news");
        break;
      case 5:
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleItemClickUser = (index) => {
    setActiveMenu(index);
    switch (index) {
      case 0:
        navigate("/dashboard");
        break;
      case 1:
        navigate("/journey");
        break;
      case 2:
        navigate("/story");
        break;
      case 3:
        navigate("/universities");
        break;
        case 4:
          navigate("/scholarship");
          break;
      case 5:
        handleLogout();
        break;
      default:
        break;
    }
  };

  const decesionMenu =
    adminSelector?.user?.user_type === "admin" ? Adminmenu : Usermenu;

  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      adminSelector?.user?.user_type !== "admin" &&
      location.pathname === "/journey"
    ) {
      setActiveMenu(1);
    }
    if (
      adminSelector?.user?.user_type !== "admin" &&
      location.pathname === "/story"
    ) {
      setActiveMenu(2);
    }
    if (
      adminSelector?.user?.user_type !== "admin" &&
      location.pathname === "/dashboard"
    ) {
      setActiveMenu(0);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      {width > 999 && (
        <div className="MainCustomSidebarr">
          <ListGroup as="ul" className="list-group-flush text-start">
            {decesionMenu.map((item, index) => (
              <ListGroup.Item
                key={index}
                className="border-0 bg-transparent"
                as="li"
                active={activeMenu === index}
                onClick={() => {
                  if (adminSelector?.user?.user_type === "admin") {
                    handleItemClick(index);
                  } else {
                    handleItemClickUser(index);
                  }
                }}
              >
                <img src={item.img} alt="img" />
                {item.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
      <div
      id="mobile-navbar"
        className={`MainCustomSidebarr ${props.menuToggle ? "open" : "closed"}`}
      >
        <ListGroup as="ul" className="list-group-flush text-start">
          {decesionMenu.map((item, index) => (
            <ListGroup.Item
              key={index}
              className="border-0 bg-transparent"
              as="li"
              active={activeMenu === index}
              onClick={() => {
                if (adminSelector?.user?.user_type === "admin") {
                  handleItemClick(index);
                  props.setMenuToggle(false);
                } else {
                  handleItemClickUser(index);
                  props.setMenuToggle(false);
                }
              }}
            >
              <img src={item.img} alt="img" />
              {item.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </>
  );
};

export default ResponsiveSidebar;
