import React, { useEffect, useState } from 'react';
import { fixNews } from '../../constant/commonUtils';
import { data_error } from '../../constant/WebText';
import { useDispatch, useSelector } from 'react-redux';
import { newsData } from '../../Redux/NewsAction';

const ViewAll = () => {
    const dispatch = useDispatch();
    const tokenSelector = useSelector((state) => state.rootReducer?.user?.token);
    const newsListing = useSelector((state) => state.News_Reducer?.newsListing);

    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        dispatch(newsData('', tokenSelector));
    }, []);

    const handleViewAll = () => {
        setShowAll(!showAll);
    };

    const visibleNews = showAll ? newsListing : newsListing?.slice(0, 3);

    return (
        <>
            <div className="card-view mb-4">
                <div className="card-body">
                    <div className="card-content">
                        <h5 className="card-title">News</h5>
                        {newsListing && newsListing.length > 0 ? (
                            <>
                                <div className="card-content" style={{maxHeight:'200px', overflowY: 'auto' }}>
                                    {visibleNews.map((item, index) => (
                                        <div key={index}>
                                            <p className="card-text">{fixNews(item.title)}</p>
                                            <a href={item.link} className="card-link" target="_blank" rel="noopener noreferrer">Read More</a>
                                        </div>
                                    ))}
                                </div>

                                {!showAll && newsListing.length > 3 && (
                                    <div className="text-center">
                                        <a onClick={handleViewAll} className="card-link" style={{ cursor: 'pointer' }}>View All News</a>
                                    </div>
                                )}

                                {showAll && (
                                    <div className="text-center">
                                        <a onClick={handleViewAll} className="card-link" style={{ cursor: 'pointer' }}>Show Less</a>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="text-center empty-text-holder dashboard">
                                <h3>{data_error}</h3>
                            </div>
                        )}
                    </div>
                    <hr />
                </div>
            </div>
        </>
    );
};

export default ViewAll;
