import React from "react";
import { Modal } from "react-bootstrap";

const VedioModal = (props) => {
  return (
    <Modal
      id="join-modal"
      className="custom-modal1 custom-modal2 custom-modal3"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="ratio ratio-16x9">
            <video
              style={{ display: 'block', width: '100%' }}
              controls
              autoPlay 
            >
              <source src="https://thepathfinderhub.com/pathfinder-vid.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VedioModal;
