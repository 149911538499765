import React from "react";
import { redBucket } from "../constant/images";

const AddListing = (props) => {
  const {
    handleDeleteScores,
    handleDeleteMajors,
    // scoresList,
    interestedData,
    miniTab,
    psatData,
    satData
  } = props;

  return (
    <>
    {['psat', 'sat'].includes(miniTab) && (
  <div className="form-group-activites-list">
    <div className="form-group-activites-list-block">
      {(miniTab === 'psat' ? psatData : satData)
        ?.filter((item) => item.type === miniTab)
        .map((item, index) => (
          <div className="row g-3" key={index}>
            <div className="col">{item.math}</div>
            <div className="col">{item.reading}</div>
            <div className="col">{item.total}</div>
            <div className="col">{item.date}</div>
            <div className="col">
              <img
                className="as-link"
                src={redBucket}
                alt="Delete"
                onClick={() => handleDeleteScores(index)}
              />
            </div>
          </div>
        ))}
    </div>
  </div>
)}


      {interestedData?.length > 0 && (
        <div className="form-group-activites-list">
          <div className="form-group-activites-list-block">
            {interestedData.map((item, index) => (
              <div class="row g-3" key={index}>
                <div class="col">{item}</div>
                <div class="col">
                  <img
                    className="as-link"
                    src={redBucket}
                    alt="Delete"
                    onClick={() => handleDeleteMajors(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AddListing;
