import React, { useEffect, useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiBookmark, BiSolidBookmark } from "react-icons/bi";
import SpinnerLoader from "../../webLoader/SpinnerLoader";
import { cardlisting, convertObjectToString, styleComponent } from "../../constant/commonUtils";
import { IoBookmarkOutline } from "react-icons/io5";
import { IoBookmark } from "react-icons/io5";
import { scrollAdjust } from "../../constant/commonUtils";
import QuetionsModal from "../Modals/QuetionsModal";
import SportsmanshipModal from "../Modals/SportsmanshipModal";
import { data_error } from "../../constant/WebText";
import { height_action } from "../../Redux/HeightAction";
import { useDispatch, useSelector } from "react-redux";
import { scroll_action } from "../../Redux/ScrollableAction";
import VedioModal from "../Modals/VedioModal";
import DashboardTab from "./DashboardTab";
import MiniCard from "../Dashoard/MiniCard";
import ViewAll from "../Dashoard/ViewAll";
import Calendar from "../Dashoard/Calander";
import MaskUser from "../Dashoard/MaskUser";
const PlayGround = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [questionDetail, setQuestionDetail] = React.useState("");
  const [questionDetailEdit, setQuestionDetailEdit] = React.useState("");
  const dispatch = useDispatch();
  const [showModel, setShowModel] = useState(false);
  const displayVideo = useSelector((state) => state.URL_reducer);
  const adminSelector = useSelector((state) => state.rootReducer);

  const {
    title,
    searchQuery,
    setSearchQuery,
    questions,
    save_question,
    selectedQuestions,
    tabtype,
    setTabtype,
    spinnerLoader,
    setQuestions,
    selected_questions_listing,
    dropdownId,
    setdropdownId,
    subId,
    DropDownValues,
  } = props;

  const popover = (description) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>{description}</Popover.Body>
      </Popover>
    );
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const UnselectedArray = questions?.filter((item) => !item.selected);
  const savedArray = questions?.filter((item) => item.saved);

  console.log(savedArray, "savedArraysavedArraysavedArray");
  console.log(UnselectedArray, "UnselectedArrayUnselectedArrayUnselectedArray");
  console.log(selectedQuestions, "selectedQuestionsselectedQuestions");

  if (questions.length > 0) {
    setTimeout(() => {
      scrollAdjust();
    }, 1500);
  }
  if (selectedQuestions.length > 0) {
    setTimeout(() => {
      scrollAdjust();
    }, 1500);
  }

  useEffect(() => {
    if (tabtype === "list") {
      dispatch(height_action(UnselectedArray, "list"));
    } else if (tabtype === "saved") {
      dispatch(height_action(savedArray, "saved"));
    } else if (tabtype === "select") {
      dispatch(height_action(selectedQuestions, "select"));
    }
    // eslint-disable-next-line
  }, [questions, tabtype, selectedQuestions]);

  const reduxHeight = useSelector((state) => state.height_reducer);
  const dataLength = reduxHeight?.data?.data;

  const containerRef = useRef(null);
  const container = containerRef.current;

  useEffect(() => {
    if (dataLength && container) {
      setTimeout(() => {
        if (container && container.scrollHeight > container.clientHeight) {
          dispatch(scroll_action(true));
        } else {
          dispatch(scroll_action(false));
        }
      }, 100);
    }
    // eslint-disable-next-line
  }, [dataLength, container, questions, selectedQuestions]);

  useEffect(() => {
    const videoModalClosed = localStorage.getItem("videoModalClosed");
    if (displayVideo?.data?.url === "/onboarding" && !videoModalClosed) {
      setShowModel(true);
    }
  }, [displayVideo]);

  const handleCloseVideoModal = () => {
    setShowModel(false);
    localStorage.setItem("videoModalClosed", "true");
  };

  const handleUserAction = (item, type) => {
    if (type === "save") {
      save_question(item.id);
    } else {
      if (dropdownId === "sportsmanship") {
        setModalShow1(true);
        setQuestionDetail(item);
      } else {
        setQuestionDetail(item);
        setModalShow(true);
      }
    }
  };

  

  return (
    <>
      {/* {spinnerLoader && <SpinnerLoader />} */}
      {/* <div className="row main-tabs-block position-relative">
        <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 main-tabs-contain">
          <div className="playground_tab_holder">
            <div className="main-search-blocks playground-search">
              <div className="search-input-holder">
                <Form.Control
                  size="lg"
                  type="search"
                  className="search-input"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <ul className="nav-tabs list-unstyled d-flex">
              <li className="nav-item as-link">
                <a
                  href={() => false}
                  className={
                    tabtype === "list" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    setTabtype("list");
                  }}
                >
                  <span>List</span>
                </a>
              </li>
              <li className="nav-item as-link">
                <a
                  href={() => false}
                  className={
                    tabtype === "select" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    setTabtype("select");
                  }}
                >
                  <span>Selected</span>
                </a>
              </li>
              <li className="nav-item as-link">
                <a
                  href={() => false}
                  className={
                    tabtype === "saved" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    setTabtype("saved");
                  }}
                >
                  <span>Save</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="playground-tab">
            <div className="our-custom-tab-content">
              <h4>
                {((tabtype === "list" || tabtype === "saved") && subId === "roles_activites") ?
                  "Select All Roles and Activities that Apply" :
                  ((tabtype === "list" || tabtype === "saved") && subId === "honors_awards") ?
                    "Select All Honors and Awards that Apply"
                    : (tabtype === "select" && subId === "roles_activites") ? (
                      <span>
                        Review Selected Roles and Activities
                      </span>
                    )
                      : (tabtype === "select" && subId === "honors_awards") ? (
                        <span>
                          Review Selected Honors and Awards
                        </span>
                      ) : ""}
              </h4>
              <p>
                When a rising senior is applying to colleges or universities,
                demonstrating a solid record of scholarship is essential. Here's
                a list of participations and accomplishments that can help
                bolster their application
              </p>
            </div>

            {tabtype === "select" && (
              <>
                {selectedQuestions.length > 0 ? (
                  <div className="table-responsive all-tabs-table-holder Journey-tabs-table">
                    <Table
                      style={{ padding: "0px" }}
                      className="alltabs-list-table table"
                    >
                      <thead>
                        {selectedQuestions?.length > 0 && (
                          <tr>
                            <th>{title}</th>
                            <th>Participation Frequency</th>
                          </tr>
                        )}
                      </thead>
                      <tbody className="Journey-responsive" ref={containerRef}>
                        {selectedQuestions &&
                          selectedQuestions?.length > 0 &&
                          selectedQuestions &&
                          selectedQuestions?.map((question, index) => (
                            <tr key={index}>
                              <td>
                                <div className="circle-icon-holder">
                                  <h4>
                                    {question.title}
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={popover(question.description)}
                                    >
                                      <span>
                                        <AiOutlineExclamationCircle />
                                      </span>
                                    </OverlayTrigger>
                                  </h4>
                                </div>
                                {question && question.answers.length > 0 && question.answers.map((answer, index)=>(
                                  <div index={index} className="circle-icon-holder circle-icon-child-holder">
                                  <span>
                                    {answer.answer}
                                  </span>                                 
                                </div>
                                ))}
                              </td>
                              <td className="playground-section">
                              <div className="bookmark-group" >
                              {question && question.answers.length > 0 && question.answers.map((answer, index) => (
                                    <h4 index={index}>
                                      {convertObjectToString(answer)}
                                      </h4>
                                ))}
                                </div>
                                <div className="bookmark-icon-holder">
                                  <button
                                    type="button"
                                    className="custm-selct-icon-btn btn btn-primary btn-md"
                                    onClick={() => {
                                      if (dropdownId === "sportsmanship") {
                                        setModalShow1(true);
                                        setQuestionDetail(question);
                                        setQuestionDetailEdit(question.answers.length > 0 && question?.answers);
                                      } else {
                                        setQuestionDetail(question);
                                        setQuestionDetailEdit(question.answers.length > 0 && question?.answers);
                                        setModalShow(true);
                                      }
                                    }}
                                  >
                                    Edit
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  spinnerLoader && <div className="no_data_found"></div>
                )}
                {!spinnerLoader &&
                  selectedQuestions &&
                  selectedQuestions.length === 0 && (
                    <div className="text-center empty-text-holder">
                      <h3>{data_error}</h3>
                    </div>
                  )}
              </>
            )}
            {tabtype === "saved" && (
              <>
                {savedArray.length > 0 ? (
                  <div className="table-responsive playground-saved all-tabs-table-holder Journey-tabs-table">
                    <Table
                      style={{ padding: "0px" }}
                      className="alltabs-list-table table"
                    >
                      <thead>
                        {savedArray?.length > 0 && (
                          <tr>
                            <th>{title}</th>
                            <th></th>
                          </tr>
                        )}
                      </thead>
                      <tbody className="Journey-responsive" ref={containerRef}>
                        {savedArray &&
                          savedArray?.length > 0 &&
                          savedArray &&
                          savedArray?.map((question, index) => (
                            <tr key={index}>
                              <td>
                                <div className="circle-icon-holder">
                                  <h4>
                                    {question.title}

                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={popover(question.description)}
                                    >
                                      <span>
                                        <AiOutlineExclamationCircle />
                                      </span>
                                    </OverlayTrigger>
                                  </h4>
                                </div>
                              </td>

                              <td>
                                <div className="bookmark-icon-holder">
                                  <span
                                    className={question.saved ? "active" : undefined}
                                    onClick={() => save_question(question.id)}
                                  >
                                    {question.saved ? (
                                      <BiSolidBookmark />
                                    ) : (
                                      <BiSolidBookmark />
                                    )}
                                  </span>
                                  <Button
                                    variant="primary"
                                    className="custm-selct-icon-btn"
                                    size="md"
                                    onClick={() => {
                                      if (dropdownId === "sportsmanship") {
                                        setModalShow1(true);
                                        setQuestionDetail(question);
                                      } else {
                                        setQuestionDetail(question);
                                        setModalShow(true);
                                      }
                                    }}
                                  >
                                    Select
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  spinnerLoader && <div className="no_data_found"></div>
                )}

                {!spinnerLoader && savedArray && savedArray.length === 0 && (
                  <div className="text-center empty-text-holder">
                    <h3>{data_error}</h3>
                  </div>
                )}
              </>
            )}
            {tabtype === "list" && (
              <>
                {UnselectedArray.length > 0 ? (
                  <div className="table-responsive all-tabs-table-holder Journey-tabs-table">
                    <Table
                      style={{ padding: "0px" }}
                      className="alltabs-list-table table"
                    >
                      <thead>
                        {UnselectedArray?.length > 0 && (
                          <tr>
                            <th>{title}</th>
                            <th></th>
                          </tr>
                        )}
                      </thead>
                      <tbody className="Journey-responsive" ref={containerRef}>
                        {UnselectedArray &&
                          UnselectedArray?.length > 0 &&
                          UnselectedArray &&
                          UnselectedArray?.map((question, index) => (
                            <tr key={index}>
                              <td>
                                <div className="circle-icon-holder">
                                  <h4>
                                    {question.title}
                                 
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      overlay={popover(question.description)}
                                    >
                                      <span>
                                        <AiOutlineExclamationCircle />
                                      </span>
                                    </OverlayTrigger>
                              
                                  </h4>
                                </div>
                             
                              </td>

                              <td>
                                <div className="bookmark-icon-holder">
                                  <span
                                    className={question.saved ? "active" : undefined}
                                    onClick={() => save_question(question.id)}
                                  >
                                    {question.saved ? (
                                      <IoBookmark />
                                    ) : (
                                      <IoBookmarkOutline />
                                    )}
                                  </span>
                                  <Button
                                    variant="primary"
                                    className="custm-selct-icon-btn"
                                    size="md"
                                    onClick={() => {
                                      if (dropdownId === "sportsmanship") {
                                        setModalShow1(true);
                                        setQuestionDetail(question);
                                      } else {
                                        setQuestionDetail(question);
                                        setModalShow(true);
                                      }
                                    }}
                                  >
                                    Select
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  spinnerLoader && <div className="no_data_found"></div>
                )}

                {!spinnerLoader &&
                  UnselectedArray &&
                  UnselectedArray.length === 0 && (
                    <div className="text-center empty-text-holder">
                      <h3>{data_error}</h3>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div> */}

      <div className={"line-chart-main-container-user"}>
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-9 pe-4 progress-3">
              <DashboardTab
                tabs={[
                  { name: "List", id: "list" },
                  { name: "Selected", id: "select" },
                  { name: "Save", id: "save" },
                ]}
                setTabtype={setTabtype}
                tabType={tabtype}
                dropdownListing={DropDownValues}
                dropdownSelected={setdropdownId}
                dropdownSelectedId={dropdownId}
                listing={
                  tabtype === "list"
                    ? UnselectedArray
                    : tabtype === "save"
                    ? savedArray
                    : tabtype === "select" && selectedQuestions
                }
                userAction={handleUserAction}
                type={"journey"}
                setSearhQuery={setSearchQuery}
                searchQuery={searchQuery}
                // nameClasss={'journey-listingsss'}
                styleComponent={styleComponent}
                spinnerLoader={spinnerLoader}
                
              />
            </div>

            <div className="col-md-3 col-sm-3 mb-4 ps-0 calander-deshboards">
              <div className="">
                <MiniCard cardlisting={[cardlisting[2]]} />
              </div>
              <div className="mb-4">
                <Calendar />
              </div>
              <ViewAll />

              <MaskUser />
            </div>
          </div>
        </div>
      </div>

      {modalShow && (
        <QuetionsModal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            if (tabtype === "select") {
              selected_questions_listing();
            }
          }}
          showData={questionDetail}
          setQuestions={setQuestions}
          questionsListing={questions}
          questionDetailEdit={tabtype === "select" ? questionDetailEdit : false}
        />
      )}
      {modalShow1 && (
        <SportsmanshipModal
          show={modalShow1}
          onHide={() => {
            setModalShow1(false);
            if (tabtype === "select") {
              props.selected_questions_listing();
            }
          }}
          setQuestions={setQuestions}
          showData={questionDetail}
          questionsListing={questions}
          questionDetailEdit={tabtype === "select" ? questionDetailEdit : false}
        />
      )}
      <VedioModal
        show={showModel}
        onClose={handleCloseVideoModal}
        onHide={handleCloseVideoModal}
        setShowModel={setShowModel}
        backdrop="static"
        keyboard={false}
      />
    </>
  );
};

export default PlayGround;
