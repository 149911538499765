import React, { useEffect, useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiBookmark, BiSolidBookmark } from "react-icons/bi";
import SpinnerLoader from "../../webLoader/SpinnerLoader";
import { cardlisting, convertObjectToString, DropDownValues, getPlainText, styleComponent } from "../../constant/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import PricingModal from "../Modals/PricingModal";
import { data_error, payment_error_message } from "../../constant/WebText";
import QuetionsModal from "../Modals/QuetionsModal";
import SportsmanshipModal from "../Modals/SportsmanshipModal";
import { height_action } from "../../Redux/HeightAction";
import { scroll_action } from "../../Redux/ScrollableAction";
import AddNewsModel from "../Modals/AddNewsModel";
import { frame3_icon, redBucket } from "../../constant/images";
import commonApi from "../../CommonApi/CommonServices";
import { errorMessage, successMessage } from "../../Errors/Toast";
import DashboardTab from "./DashboardTab";
import MaskUser from "../Dashoard/MaskUser";
import ViewAll from "../Dashoard/ViewAll";
import Calendar from "../Dashoard/Calander";
import MiniCard from "../Dashoard/MiniCard";

const News = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [showNewsModal, setShowNewsModal] = useState(false);

  const [questionDetailEdit, setQuestionDetailEdit] = useState("");
  const {
    title,
    searchQuery,
    setSearchQuery,
    questions,
    save_question,
    selectedQuestions,
    spinnerLoader,
    setQuestions,
    hanleAddNews
  } = props;
  const [questionDetail, setQuestionDetail] = useState({});
  const [tabtype, setTabtype] = useState('list');
  const userSelector = useSelector((state) => state.rootReducer);
  const dispatch = useDispatch();
  const [showModel, setShowModel] = useState(false);

  const tokenSelector = useSelector((state) => state.rootReducer?.user?.token);
  const chooseUser = useSelector((state) => state.rootReducer);

  const [dropdownId, setdropdownId] = useState(DropDownValues[0].id);

  const popover = (description) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>{getPlainText(description)}</Popover.Body>
      </Popover>
    );
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleId = (itemId) => {
    save_question(itemId);
  };

//   useEffect(() => {
//     if (pricingSelector?.user?.payment_status === false) {
//       setShowModel(true);
//     }
//     // eslint-disable-next-line
//   }, []);

  const UnselectedArray = questions

  useEffect(()=>{
    if(tabtype === "list"){
    dispatch(height_action(UnselectedArray, "list"))
    }
    // else if(tabtype === "saved"){
    //   dispatch(height_action(savedArray, "saved"))
    // }else if(tabtype === "select"){
    //   dispatch(height_action(selectedQuestions, "select"))
    // }
    // eslint-disable-next-line
  },[props.questions, tabtype, selectedQuestions])


  const reduxHeight = useSelector((state)=>state.height_reducer); 
  const dataLength = reduxHeight?.data?.data;

  const containerRef = useRef(null);
  const container = containerRef.current;

  useEffect(() => {
    if (dataLength && (container)) {
      setTimeout(() => {
        if (
          (container && (container.scrollHeight > container.clientHeight))) {
          dispatch(scroll_action(true));
        } else {
          dispatch(scroll_action(false));
        }
      }, 100);
    }
    // eslint-disable-next-line
  }, [dataLength, container, tabtype, props.questions, selectedQuestions]);


  const delete_news = (id) => {
    const payload = new FormData();

    payload.append("news_id", id);
    
    commonApi
      .delete_news(payload, tokenSelector)
      .then((res) => {
        if (res.status === 200 && res.success) {

          const newquestions = questions.filter((item, index) => item.id !== id);
          setQuestions(newquestions);

        successMessage(res.message)
        } else {
          errorMessage(res.message);
        }
      })
      .catch((err) => {
        console.log("Err", err);
        return { type: "error", message: err.message };
      });
  };

  const openModal = ()=>{
    setShowNewsModal(true)
  }

const hanleEditNews =(data)=>{
    setQuestions((prevData) =>
      prevData.map((item) => item.id === data.id ? data : item)
    ); 

}


const handleUserAction = (item,type) => {

  if (type === 'select') {
    setShowNewsModal(true);
    setQuestionDetail(item);
  } else if(type === 'delete'){
    delete_news(item.id)
  }

};



  return (
    <>
      {/* {spinnerLoader && <SpinnerLoader />} */}

      {/* <div className="row main-tabs-block position-relative">
        <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 main-tabs-contain">
          <div className="playground_tab_holder">
            <div className="main-search-blocks journey-search">
              <div className="search-input-holder">
                <Form.Control
                  size="lg"
                  type="search"
                  value={searchQuery}
                  className="search-input"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <ul className="nav-tabs list-unstyled d-flex">
              <li className="nav-item as-link">
                <a
                  href={() => false}
                  className={
                    tabtype === "list" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    setTabtype("list");
                  }}
                >
                  <span>List</span>
                </a>
              </li>
              
            </ul>
          </div>

          <div className="playground-tab">

          <h4>
            {((tabtype === "list" || tabtype === "saved") && subId === "roles_activites") ? "Select All Roles and Activities that Apply" :
            ((tabtype === "list" || tabtype === "saved") && subId === "honors_awards") ? "Select All Honors and Awards that Apply" :
            (tabtype === "select" && subId === "roles_activites") ? "Review Selected Roles and Activities" :
            (tabtype === "select" && subId === "honors_awards") ? "Review Selected Honors and Awards" :
            ""}
          </h4>

            <p>
              When a rising senior is applying to colleges or universities,
              demonstrating a solid record of scholarship is essential. Here's a
              list of participations and accomplishments that can help bolster
              their application
            </p>

           
            {tabtype === "list" && (
              <>
                {UnselectedArray.length > 0 ? (
                  <div className="table-responsive all-tabs-table-holder Journey-tabs-table">
                    <Table
                      style={{ padding: "0px" }}
                      className="alltabs-list-table table"
                    >
                      <thead>
                        {UnselectedArray?.length > 0 && (
                          <tr>
                            <th>{title}</th>
                            <th></th>
                          </tr>
                        )}
                      </thead>

                        <tbody className="Journey-responsive" ref={containerRef}>
                          {UnselectedArray &&
                            UnselectedArray?.length > 0 &&
                            UnselectedArray &&
                            UnselectedArray?.map((question, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="circle-icon-holder">
                                    <h4>
                                      {question.title}
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={popover(question.description)}
                                      >
                                        <span>
                                          <AiOutlineExclamationCircle />
                                        </span>
                                      </OverlayTrigger>
                                   
                                    </h4>
                                  </div>
                                </td>

                                <td>
                                  <div className="as-link bookmark-icon-holder">
                                     <span
                                      onClick={() => handleDelete(index, question.id)}
                                    >
                                      <img src={redBucket} alt="Delete" />
                                    </span> 
                                    <Button
                                      variant="primary"
                                      className="custm-selct-icon-btn"
                                      size="md"
                                      onClick={() => {

                                        setShowNewsModal(true);
                                        setQuestionDetail(question);
                                     
                                       } }
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                    </Table>
                  </div>
                ) : (
                  spinnerLoader && <div className="no_data_found"></div>
                )}

                {!spinnerLoader &&
                  UnselectedArray &&
                  UnselectedArray.length === 0 && (
                    <div className="text-center empty-text-holder">
                      <h3>
                        { data_error}
                      </h3>
                    </div>
                  )}
              </>
            )}

           
          </div>
        </div>
      </div> */}


     
      <div
        className={
         
            "line-chart-main-container-user"
        }
        
      >
                <div className="container">
                <div className="row">
                <div className="col-md-9 col-sm-9 pe-4 progress-3">


      <DashboardTab
                tabs={[
                  { name: "List", id: "list" },
                ]}
                setTabtype={setTabtype}
                tabType={tabtype}
                dropdownListing={DropDownValues}
                dropdownSelected={setdropdownId}
                dropdownSelectedId={dropdownId}
                listing={
                  tabtype === 'list' &&
                  UnselectedArray
                }
                userAction={handleUserAction}
                type={'news'}
                setSearhQuery={setSearchQuery}
                searchQuery={searchQuery}
                userType={chooseUser?.user?.user_type}
                group={'news'}
                styleComponent={styleComponent}
                spinnerLoader={spinnerLoader}
              />
              </div>

<div className="col-md-3 col-sm-3 mb-4 ps-0 calander-deshboards">
<div>
                <MiniCard
                  handleShowModel={openModal}
                  cardlisting={
                    userSelector?.user?.user_type === "user"
                      ? [cardlisting[2]]
                      : [{ uperName: "Add News", img: frame3_icon }]
                  }
                />
              </div>
              <div className="mb-4">
                <Calendar />
              </div>
                {/* <ViewAll /> */}
              
              {userSelector?.user?.user_type === "user" &&
              <MaskUser />
}
            </div>
              </div>
              </div>
              </div>


     
      {modalShow1 && (
        <SportsmanshipModal
          show={modalShow1}
          onHide={() => {
            setModalShow1(false);
            if (tabtype === "select") {
              props.selected_questions_listing();
            }
          }}
          setQuestions={setQuestions}
          showData={questionDetail}
          questionsListing={questions}
          questionDetailEdit={tabtype === "select" ? questionDetailEdit : false}
        />
      )}

      {/* <CustomModalTwo show={modalShow2} onHide={() => setModalShow2(false)} /> */}

      <PricingModal
        show={showModel}
        onClose={() => setShowModel(false)}
        setShowModel={setShowModel}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModel(false)}
      />

      {showNewsModal && (
      <AddNewsModel
      show={showNewsModal} 
      onHide={() => {
        setShowNewsModal(false);
        setQuestionDetail({})
      }
      }
      hanleEditNews={(data) => {hanleEditNews(data)}}
      hanleAddNews={hanleAddNews}
      questionDetail={questionDetail ? questionDetail : {}}
       />
      )
}
     
    </>
  );
};

export default News;
