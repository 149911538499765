import React, { useEffect, useState } from 'react';
import { chevron_icon, Vector_icon, arrow_icon } from '../../constant/images';
import get_scholarship from '../../Redux/GetScholarshipReducer';
import { useDispatch, useSelector } from 'react-redux';
import { get_scholarships } from '../../Redux/GetScholarshipAction';

const Calendar = () => {

    const dispatch = useDispatch();
    const tokenSelector = useSelector((state) => state.rootReducer?.user?.token);
    const scholarshipListing = useSelector((state) => state.get_scholarship?.scholarshipListing); 

    useEffect(() => {
        dispatch(get_scholarships('', tokenSelector)); 
    }, []);
    





    const [currentDate, setCurrentDate] = useState(new Date());

    const getMonthName = (date) => {
        return date.toLocaleString('default', { month: 'long' });
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const startDayOfMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    };

    const prevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const renderHeader = () => {

        return (
            <div className="calendar-header">
                <button onClick={prevMonth}><img src={Vector_icon} alt="icon" /></button>
                <span>{getMonthName(currentDate)} {currentDate.getFullYear()}</span>
                <button onClick={nextMonth}><img src={chevron_icon} alt="icon" /></button>
            </div>
        );
    };

    const renderDays = () => {
        const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        return (
            <div className="calendar-days">
                {days.map((day, index) => (
                    <div key={index} className="calendar-day">
                        {day}
                    </div>
                ))}
            </div>
        );
    };
    const ScholarshipCard = () => {
        return (
            <div className="scholarship-calander">
                <h4 className="scholarship">Upcoming Scholarships</h4>
                <div className="card p-3 mt-2 mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <small className="text-muted">
                                <span className="text-primary">&#x25CF;</span> 8 Jan 2023
                            </small>
                            <h5 className="mt-2 mb-1">School Annual Function</h5>
                            <p className="mb-0">$5,000</p>
                        </div>
                        <div>
                            <img src={arrow_icon} alt="icon" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderCells = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const daysInMonth = getDaysInMonth(year, month);
        const startDay = startDayOfMonth(currentDate);

        const cells = [];
        let day = 1;

        // Empty cells for previous month's days
        for (let i = 0; i < startDay; i++) {
            cells.push(<div key={`empty-${i}`} className="calendar-cell empty"></div>);
        }

        // Cells for current month's days
        for (let i = startDay; i < daysInMonth + startDay; i++) {
            cells.push(
                <div key={i} className={`calendar-cell ${day === currentDate.getDate() ? 'selected' : ''}`}>
                    {day}
                </div>
            );
            day++;
        }

        return <div className="calendar-cells">{cells}</div>;
    };

    return (
        <div className="calendar">
            {ScholarshipCard()}
            {renderHeader()}
            {renderDays()}
            {renderCells()}
        </div>
    );
};

export default Calendar;
