import React, { useState, useEffect } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { pathFinderLogo } from "../../constant/images";
import { NavLink } from "react-router-dom";
import commonApi from "../../CommonApi/CommonServices";
import { errorMessage, successMessage } from "../../Errors/Toast";
import { useDispatch } from "react-redux";
import { login, signUp } from "../../Redux/LoginAction";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const GetStartedScrn = () => {
  const [loginForm, setLoginForm] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  

  const history = useNavigate();
  const location = useLocation();

  const Gender = [
    {
      title: "Female",
      id: "female",
    },
    {
      title: "Male",
      id: "male",
    },
  ];
  const [validated, setValidated] = useState(false);
  const [showLogin, setShowLogin] = useState("login");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isValidInput, setIsValidInput] = useState(true);
  const [subId, setSubId] = useState(Gender[0].id);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      let isValidEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        value
      );
      setIsValidInput(isValidEmail);
    }

    setLoginForm({ ...loginForm, [name]: value });
  };

  const handle = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (
      form.checkValidity() === false ||
      (!passwordMatch && showLogin !== "login") ||
      !isValidInput
    ) {
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setValidated(false);
      if (!showForgotPassword && showLogin === "login") {
        Login();
      } else {
        if (showForgotPassword) {
          forgot_password();
        } else {
          signup();
        }
      }
    }
  };

  const signup = () => {
    // Create a new FormData object
    const formData = new FormData();

    // Append the fields from the data object to the FormData object
    formData.append("name", loginForm.name);
    formData.append("contact", loginForm.email);
    formData.append("password", loginForm.password);
    formData.append("confirm_password", loginForm.confirmPassword);
    formData.append("gender", subId);

    // Make the API call using the FormData object
    commonApi
      .signup(formData)
      .then((res) => {
        if (res.status === 200 && res.success) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("gender", subId);

          dispatch(signUp(subId, res.token));

          history("/onboarding");
        } else {
          errorMessage(res.message);
        }
      })

      .catch((err) => {
        console.log("Err", err);
      });
  };
  const Login = () => {
    // Create a new FormData object
    const formData = new FormData();

    // Append the fields from the data object to the FormData object
    formData.append("contact", loginForm.email);
    formData.append("password", loginForm.password);

    // Make the API call using the FormData object
    commonApi
      .login(formData)
      .then((res) => {
        if (res.status === 200 && res.success) {
          // Handle success

          localStorage.setItem("token", res.token);
          localStorage.setItem("user_type", res.user_type);
          localStorage.setItem("gender", res.gender);
          localStorage.setItem("payment_status", res.payment_status);
          localStorage.setItem("onboarding", res.onboarding);

          dispatch(
            login(
              res.gender,
              res.user_type,
              res.onboarding,
              res.payment_status,
              res.token
            )
          );
          if (res.user_type === "admin") {
            history("/journey");
          } else {
            if (res.onboarding === true) {
              if (res.payment_status === true) {
                history("/journey");
              } else {
                history("/playground");
              }
            } else {
              history("/onboarding");
            }
          }
        } else {
          errorMessage(res.message);
        }
      })
      .catch((err) => {
        console.log("Err", err);
        return { type: "error", message: err.message };
      });
  };
  const forgot_password = () => {
    // Create a new FormData object
    const formData = new FormData();
    formData.append("email", loginForm.email);

    // Make the API call using the FormData object
    commonApi
      .forgot_password(formData)
      .then((res) => {
        if (res.status === 200 && res.success) {
          successMessage(res.message);

          setShowForgotPassword(false);
        } else {
          errorMessage(res.message);
        }
      })
      .catch((err) => {
        console.log("Err", err);
        return { type: "error", message: err.message };
      });
  };

  useEffect(() => {
    if (location.state && location.state?.type === "signup") {
      setShowLogin("signup");
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setLoginForm({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      gender: "",
    });
    setValidated(false);
  }, [showLogin]);

  useEffect(() => {
    if (loginForm.password !== loginForm.confirmPassword) {
      if (loginForm.password && loginForm.confirmPassword) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    } else {
      setPasswordMatch(true);
    }
  }, [loginForm.password, loginForm.confirmPassword]);

  const handleSubDropdownChange = (id) => {
    setSubId(id);
  };

  const CustomDropdownItem = ({ children, onClick }) => (
    <a
      onClick={onClick}
      className="dropdown-item"
      style={{ cursor: 'pointer' }}
      role="button"
      tabIndex="0"
    >
      {children}
    </a>
  );

  return (
    <>
      <div className="main-app-wrapper">
        <Row className="gets-started-block">
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={
              showLogin === "login"
                ? "gets-login-contain pe-0 ps-0"
                : "gets-started-contain pe-0 ps-0"
            }
          >
            <div className="logo-holder">
              <img src={pathFinderLogo} alt="path-finder-logo" />
            </div>
            <div className="get-started-info-holder">
              <h1>
                {showLogin === "login" ? "Welcome Back!" : "Let’s Get Started"}
              </h1>
              <p>
                {" "}
                {showLogin === "login"
                  ? "Let’s get started"
                  : "Your dream school is around the corner"}
              </p>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="gets-started-form-contain pe-0 ps-0"
          >
            <div className="gets-started-holder gets-started-form-holder">
              <div className="form-holder">
                <Form noValidate validated={validated} onSubmit={handle}>
                  <h2>
                    {showForgotPassword && "Forget Password"}
                    {!showForgotPassword && showLogin === "login"
                      ? "Login"
                      : !showForgotPassword && "Tell us a little about you"}
                  </h2>
                  {showForgotPassword ? (
                    <div className="form-group get-started-form">
                      <Form.Label htmlFor="email">Email</Form.Label>
                      <Form.Control
                        // id="email"
                        type="email"
                        name="email"
                        required
                        placeholder=""
                        autoComplete="off"
                        value={loginForm.email}
                        onChange={handleInputChange}
                        isInvalid={!isValidInput}
                      />
                      <Form.Control.Feedback type="invalid">
                        {loginForm.email
                          ? "Email is invalid."
                          : "Email is required."}
                      </Form.Control.Feedback>
                    </div>
                  ) : (
                    <>
                      {showLogin !== "login" && (
                        <div className="form-group get-started-form">
                          <Form.Label htmlFor="name">Name</Form.Label>
                          <Form.Control
                            // id="name"
                            name="name"
                            type="text"
                            pattern="^\S.*"
                            required={showLogin !== "login" ? true : false}
                            value={loginForm.name}
                            placeholder=""
                            autoComplete="off"
                            onChange={handleInputChange}
                          />

                          <Form.Control.Feedback type="invalid">
                            {loginForm.name
                              ? "Please avoid space in start."
                              : " Name is required."}
                          </Form.Control.Feedback>
                        </div>
                      )}

                      <div className="form-group get-started-form">
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <Form.Control
                          // id="email"
                          type="email"
                          name="email"
                          required
                          placeholder=""
                          autoComplete="off"
                          value={loginForm.email}
                          onChange={handleInputChange}
                          isInvalid={!isValidInput}
                        />
                        <Form.Control.Feedback type="invalid">
                          {loginForm.email
                            ? "Email is invalid."
                            : "Email is required."}
                        </Form.Control.Feedback>
                      </div>

                      <div className="form-group get-started-form">
                        <Form.Label htmlFor="name">Password</Form.Label>
                        <Form.Control
                          // id="name"
                          name="password"
                          type="password"
                          required
                          pattern="^\S.*"
                          value={loginForm.password}
                          placeholder=""
                          autoComplete="off"
                          onChange={handleInputChange}
                        />

                        <Form.Control.Feedback type="invalid">
                          {loginForm.password
                            ? "Please avoid space in start."
                            : "Password is required."}
                        </Form.Control.Feedback>
                      </div>

                      {showLogin !== "login" && (
                        <div className="form-group get-started-form">
                          <Form.Label htmlFor="name">
                            Confirm Password
                          </Form.Label>
                          <Form.Control
                            // id="name"
                            name="confirmPassword"
                            type="password"
                            required={showLogin !== "login" ? true : false}
                            value={loginForm.confirmPassword}
                            placeholder=""
                            autoComplete="off"
                            onChange={handleInputChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Confirm password is required.
                          </Form.Control.Feedback>
                          {!passwordMatch && (
                            <div className="error_notice">
                              Passwords do not match!
                            </div>
                          )}
                        </div>
                      )}
                      {showLogin !== "login" && (
                        <div className="custom-form-slect-field get-started-form">
                          <Form.Label htmlFor="gender">Gender</Form.Label>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {subId &&
                                Gender.find((item) => item.id === subId)?.title}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {Gender.map((item, index) => (
                                <Dropdown.Item
                                 as={CustomDropdownItem}
                                  key={index}
                                  onClick={() =>
                                    handleSubDropdownChange(item.id)
                                  }
                                >
                                  {item.title}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </>
                  )}
                  {showLogin === "login" ? (
                    <NavLink
                      className="account-anchor"
                      onClick={() => {
                        if (showLogin === "login") {
                          setIsValidInput(true);
                          setValidated(false);
                          setShowForgotPassword(!showForgotPassword);
                          setLoginForm({
                            name: "",
                            email: "",
                            password: "",
                            confirmPassword: "",
                            gender: "",
                          });
                          // setIsValidInput(true);
                          // setShowLogin("");
                        } else {
                          setIsValidInput(true);
                          setValidated(false);
                         
                          // setShowLogin("login");
                        }
                      }}
                    >
                      {showForgotPassword ? "Login?" : "Forget password?"}
                    </NavLink>
                  ) : (
                    ""
                  )}

                  <button
                    type="submit"
                    className="btn btn-primary gets-form-btn"
                  >
                    Next
                  </button>
                </Form>

                {!showForgotPassword ? (
                  <NavLink
                    className="account-anchor"
                    onClick={() => {
                      if (showLogin === "login") {
                        setIsValidInput(true);
                        setValidated(false);
                        setShowLogin("");
                      } else {
                        setIsValidInput(true);
                        setValidated(false);
                        setShowLogin("login");
                      }
                    }}
                  >
                    {showLogin === "login"
                      ? "I don’t have an account"
                      : "I have an account"}
                  </NavLink>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GetStartedScrn;
