import React, { useState, useRef, useEffect } from 'react';

const DropdownTopBar = ({ items, onSelect, isOpen, setIsOpen }) => {
    // const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);



    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>

            {isOpen && (
                <ul
                    className="absolute top-full mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50"
                >
                    {items.map((item, index) => (
                        <li
                            key={index}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                                onSelect(item);
                                setIsOpen(false);
                            }}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DropdownTopBar;
